<template lang="pug">
.navbar
  .navbar__top
    img.image(
      :src="lbrLogo"
    )

    p.title LIGA BRASILEIRA DE ROBÓTICA

  .navbar__bar

  .navbar__bottom
    .links
      a.links.links__item(
        href="https://www.ligabrasileiraderobotica.com.br"
      ) Início

      a.links.links__item(
        @click="doNavigate('Fights')"
      ) Lutas

      a.links.links__item(
        @click="doNavigate('Keys')"
      ) Chaves

      a.links.links__item(
        @click="doNavigate('Robots')"
      ) Robôs

      a.links.links__item(
        href="mailto:ligabrobotica@gmail.com"
      ) Entre em contato

    .links
      .links.links__item(
        @click="doNavigate('UserProfile')"
      )
        FontAwesomeIcon.links__item--icon.links__item--big(
          :icon="['far', 'user-circle']"
        )
        p {{takeLogText}}

      .links.links__item(
        v-if="getUser.id"
        @click="doNavigate('SignIn')"
      )
        FontAwesomeIcon.links.links__item--icon.links__item--big(
          :icon="['fas', 'sign-out-alt']"
        )
        p Fazer Log Out

</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

import lbrLogo from '@/assets/lbrLogo.webp'

export default {
  name: 'Navbar',
  data: () => ({
    lbrLogo
  }),
  computed: {
    ...mapGetters('users', [
      'getUser'
    ]),
    takeLogText () {
      if (this.getUser.id) {
        return 'Perfil'
      }
      return 'Entrar'
    }
  },
  methods: {
    ...mapActions('users', [
      'setCleanStore'
    ]),
    doNavigate (value) {
      if (value && value !== this.$route.name) {
        if (value === 'UserProfile' && !this.getUser.id) {
          return this.$router.push('/SignIn')
        }
        if (value === 'SignIn') {
          localStorage.removeItem('user')
          localStorage.removeItem('token')
          this.setCleanStore()
        }
        this.$router.push(value)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.navbar
  width: 100%
  &__top
    display: flex
    align-items: center
    .title
      font-size: 24px
    .image
      margin-right: 16px
  &__bar
    margin: 24px 0 16px
    padding: 0
    height: 1px
    width: 100%
    background: #000
  &__bottom
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    .links
      display: flex
      align-items: center
      flex-wrap: wrap
      &__item
        margin: 0 8px
        color: #000
        text-decoration: none
        font-size: 14px
        cursor: pointer
        p
          margin: 0 0 0 8px

        &--icon
          font-size: 16px
          margin: 0 8px

        &--big
          font-size: 22px

      &--left
        justify-content: flex-start
      &--right
        justify-content: flex-end

</style>
